<template>
  <b-card>
    <div slot="header">
      <strong>Gerenciar Usuarios de Grupos</strong>
    </div>

    <p><router-link :to="{ name: 'Listar Usuarios de Grupos' }">Listar Regras de Grupo</router-link></p>

    <form v-on:submit.prevent="addGroupUser">
      <div class="row">

        <div class="col-lg-6">
          <div class="form-group">
              <label name="dataCriacao"><strong>Grupo</strong></label>
              <b-form-select @change="mudaUsers($event)" required v-model="groupUser.grp_ID" :options="osGrupos">
              </b-form-select>
          </div>
        </div>
<div class="col-lg-6"><div class="form-group">
  <label name="dataCriacao"><strong>&nbsp;</strong></label>
  <b-alert variant="warning" show>Selecione um grupo para ver quais usuários fazem parte dele</b-alert>
</div></div>
      </div>
      <div v-if="!groupUser.grp_ID"></div>
      <div v-else class="row justify-content-between">
        <div class="col-lg-6">
          <label>Usuários</label>

          <div class="alert alert-secondary" style="height: 400px;">
            <input type="text" class="form-control" id="nomeUsuario"
              v-model="userToFilter" v-on:keyup="filterUsers()" placeholder="Filtrar">
            <br>
            <div class="alert alert-info" v-if="usersFiltered.length === 0">
              Não há usuários para exibir
            </div>
            <div style="height: 300px; overflow-y: auto" v-if="usersFiltered.length > 0">
              <table class="table table-sm table-hover">
                <tbody>
                  <tr v-for="userItemLeft in usersFiltered" :key="userItemLeft.usr_ID">
                    <td>{{ userItemLeft.name }}</td>
                    <td style="width: 50px;">
                      <b-btn variant="success" size="sm" @click="addUser(userItemLeft.usr_ID)">incluir</b-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>

        <div class="col-lg-6">
          <label>&nbsp;</label>
          <div class="alert alert-dark" style="height: 400px;">
            <label>Inclusos</label>
            <div class="alert alert-info" v-if="groupUser.users.length === 0">
              Não há usuários selecionados
            </div>
            <div style="height: 300px; overflow-y: auto; margin-top: 27px;" v-if="groupUser.users.length > 0">
              <table class="table table-sm table-hover">
                <tbody>
                  <tr v-for="userItem in groupUser.users" :key="userItem.usr_ID">
                    <td>{{ userItem.name }}</td>
                    <td style="width: 50px;">
                      <b-btn variant="danger" size="sm" @click="removeUser(userItem.usr_ID)">Remover</b-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Salvar</button>
      
      </div>

      

    </form>

  </b-card>
</template>

<script>
export default {
  data () {
    return {
      groupUser: {
        grp_ID: null,
        users: []
      },
      userToFilter: '',
      usersFiltered: [],
      usersToInclude: [],
      osGrupos: [],
      gruposFiltered: []
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.getAllUsers()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'error',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    getAllUsers: function () {
      this.listar('gpv_User', 'name', 'is_active = 1').then(
        (response) => {
          this.usersToInclude = response
          // Verifica se a regra ja existe para este grupo
          this.listar('gp_User_Grupo', 'ugrp_ID').then(
            (response) => {
              for (var i = 0; i < response.length; i++) {
                this.gruposFiltered.push(response[i].grp_ID)
                if (i > 0 && this.gruposFiltered.indexOf(response[i].grp_ID) >= 0) {
                  this.gruposFiltered.pop()
                }
              }
              this.getGrupos()
              this.filterUsers()
            },
            (response) => {}
          )
        }, (response) => {})
    },
    addGroupUser: function () {
      if (this.groupUser.users.length === 0) {
        this.$notify({
          type: 'error',
          title: 'Ninguém foi inserido?',
          text: 'Insira pelo menos uma pessoa.'
        })
        return false
      }
      if (!this.groupUser.grp_ID) {
        this.$notify({
          type: 'error',
          title: 'Nenhum grupo selecionado?',
          text: 'Selecione um grupo ou área.'
        })
        return false
      }
      this.contar('gp_User_Grupo', 'grp_ID = ' + this.groupUser.grp_ID).then(
        (response) => {
          if (response > 0) {
            // Se for um grupo já definido
            this.remover('gp_User_Grupo', 'grp_ID = ' + this.groupUser.grp_ID).then(
              (response) => {
                for (var i = 0; i < this.groupUser.users.length; i++) {
                  this.inserir('gp_User_Grupo', {resource: [ { grp_ID: this.groupUser.grp_ID, user_ID: this.groupUser.users[i].usr_ID } ]}, '', '1').then(
                    (response) => {
                      this.$notify({
                        type: 'success',
                        title: 'Grupo atualizado com sucesso',
                        text: 'Os usuários foram associados ao grupo informado.'
                      })
                    },
                    (response) => {
                      this.$notify({
                        type: 'error',
                        title: 'Falha ao salvar',
                        text: 'Ocorreu um erro desconhecido.'
                      })
                    }
                  )
                }
              },
              (response) => {}
            )
          } else {
            for (var i = 0; i < this.groupUser.users.length; i++) {
              this.inserir('gp_User_Grupo', {resource: [ { grp_ID: this.groupUser.grp_ID, user_ID: this.groupUser.users[i].usr_ID } ]}, '', '1').then(
                (response) => {
                  this.$notify({
                    type: 'success',
                    title: 'Grupo salvo com sucesso',
                    text: 'Os usuários foram associados ao grupo informado.'
                  })
                },
                (response) => {
                  this.$notify({
                    type: 'error',
                    title: 'Falha ao salvar',
                    text: 'Ocorreu um erro desconhecido.'
                  })
                }
              )
            }
          }
        },
        (response) => {}
      )
    },
    getGrupos: function () {
      this.listar('gp_Grupo', 'grp_mnemonico', '(grp_ativo = 1) AND (gtipo_ID = 1)').then(
        (response) => {
          for (var i = 0; i < response.length; i++) {
            this.osGrupos.push({ value: response[i].grp_ID, text: response[i].grp_mnemonico })
          }
        },
        (response) => {}
      )
    },
    mudaUsers: function (evento) {
      if (typeof evento !== 'undefined') {
        this.groupUser.grp_ID = evento
      }
      if (this.groupUser.grp_ID === null) {
        return false
      }
      this.listar('gpv_User', 'name', 'is_active = 1').then(
        (response) => {
          this.usersToInclude = response
          this.filterUsers()
          this.listar('gp_User_Grupo', 'user_ID', 'grp_ID = ' + this.groupUser.grp_ID).then(
            (response) => {
              this.groupUser.users = []
              for (var i = 0; i < response.length; i++) {
                this.addUser(response[i].user_ID)
              }
              // this.filterUsers()
            },
            (response) => {
              // this.groupUser.users = []
            }
          )
        },
        (response) => {}
      )
    },
    filterUsers: function () {
      this.usersFiltered = this.usersToInclude // .toString().slice()
      if (this.userToFilter !== '') {
        var found = []
        for (var i = 0; i < this.usersToInclude.length; i++) {
          var usuariosName = this.usersFiltered[i]['usr_ID'].toLowerCase()
          if (usuariosName.indexOf(this.userToFilter.toLowerCase()) >= 0) {
            found.push(this.usersFiltered[i])
          }
        }
        this.usersFiltered = found
      }
    },
    addUser: function (_id, nofilter) {
      if (!this.groupUser.grp_ID) {
        return false
      }
      for (var i in this.usersToInclude) {
        if (this.usersToInclude[i].usr_ID === _id) {
          this.groupUser.users.push(this.usersToInclude.splice(i, 1)[0])
          if (!nofilter) {
            this.filterUsers()
          }
          return true
        }
      }
    },
    removeUser: function (_id) {
      if (!this.groupUser.grp_ID) {
        return false
      }
      for (var i in this.groupUser.users) {
        if (this.groupUser.users[i].usr_ID === _id) {
          this.usersToInclude.push(this.groupUser.users.splice(i, 1)[0])
          this.filterUsers()
        }
      }
    }
  }
}
</script>

<style>

</style>
